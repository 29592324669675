import http from './http';

const endpoint = 'api/inventory/items';

const assetService = {
  model: 'asset',

  getPage: (pageNum, params) => http.get(`${endpoint}?page=${pageNum}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: asset => http.post(`${endpoint}`, asset),

  update: asset => http.put(`${endpoint}/${asset.id}`, asset),

  delete: asset => http.delete(`${endpoint}/${asset.id}`),
};

export default assetService;
