<template>
  <div class="page-wrapper">
    <v-tabs
      v-if="$can(['inventory.categories.edit'])"
      v-model="activeTab"
      class="hide-tab-pagination"
      color="primary"
      @change="onTabChange"
    >
      <v-tab>{{ $t('assets') }}</v-tab>
      <v-tab>{{ $t('categories') }}</v-tab>
      <v-tab>{{ $t('subcategories') }}</v-tab>
      <v-tab>{{ $t('types') }}</v-tab>
      <v-tab-item transition="none" reverse-transition="none">
        <AssetTable
          :categories="assetCategoryArray"
          :loading="isDataLoading"
          :pagination="assetPagination"
          :rows="assetArray"
          :selected-rows="selectedAssets"
          :subcategories="assetSubcategoryArray"
          :types="assetTypeArray"
          @assign="assignOne"
          @assign:multiple="assignMultiple"
          @change-page="getPaginatedAssets"
          @delete="crudMixin_delete(onAssetDelete, 'asset', $event)"
          @edit="crudMixin_openForm('asset', $event)"
          @new-item="crudMixin_openForm('asset', newAssetTemplate)"
          @item-selected="toggleSelectedAsset"
          @return="returnMultiple([$event])"
          @return:multiple="returnMultiple(selectedAssets)"
          @toggle-select-all="toggleAllSelectedAssets"
          @update:filter="getPaginatedAssets(assetPagination.page, $event)"
        />
      </v-tab-item>
      <v-tab-item transition="none" reverse-transition="none">
        <AssetCategoryTable
          :rows="assetCategoryArray"
          :loading="areAssetCategoriesLoading"
          @edit="crudMixin_openForm('assetCategory', $event)"
          @delete="crudMixin_delete(onAssetCategoryDelete, 'assetCategory', $event)"
          @new-item="crudMixin_openForm('assetCategory', {})"
        />
      </v-tab-item>
      <v-tab-item transition="none" reverse-transition="none">
        <AssetSubcategoryTable
          :rows="assetSubcategoryArray"
          :loading="areAssetSubcategoriesLoading"
          @edit="crudMixin_openForm('assetSubcategory', $event)"
          @delete="crudMixin_delete(onAssetSubcategoryDelete, 'assetSubcategory', $event)"
          @new-item="crudMixin_openForm('assetSubcategory', {})"
        />
      </v-tab-item>
      <v-tab-item transition="none" reverse-transition="none">
        <AssetTypeTable
          :rows="assetTypeArray"
          :loading="areAssetTypesLoading"
          @edit="crudMixin_openForm('assetType', $event)"
          @delete="crudMixin_delete(onAssetTypeDelete, 'assetType', $event)"
          @new-item="crudMixin_openForm('assetType', {})"
        />
      </v-tab-item>

      <v-dialog
        v-model="isAssetCategoryFormOpen"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        transition="slide-y-reverse-transition"
        max-width="800"
        scrollable
      >
        <AssetCategoryForm
          :dialog="isAssetCategoryFormOpen"
          :form-item="assetCategoryFormItem"
          @create="crudMixin_created('assetCategory', $event)"
          @update="crudMixin_updated('assetCategory', $event)"
          @cancel="isAssetCategoryFormOpen = false"
        />
      </v-dialog>

      <v-dialog
        v-model="isAssetSubcategoryFormOpen"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        transition="slide-y-reverse-transition"
        max-width="800"
        scrollable
      >
        <AssetSubcategoryForm
          :dialog="isAssetSubcategoryFormOpen"
          :form-item="assetSubcategoryFormItem"
          @create="crudMixin_created('assetSubcategory', $event)"
          @update="crudMixin_updated('assetSubcategory', $event)"
          @cancel="isAssetSubcategoryFormOpen = false"
        />
      </v-dialog>

      <v-dialog
        v-model="isAssetTypeFormOpen"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        transition="slide-y-reverse-transition"
        max-width="800"
        scrollable
      >
        <AssetTypeForm
          :dialog="isAssetTypeFormOpen"
          :form-item="assetTypeFormItem"
          @create="crudMixin_created('assetType', $event)"
          @update="crudMixin_updated('assetType', $event)"
          @cancel="isAssetTypeFormOpen = false"
        />
      </v-dialog>
    </v-tabs>

    <AssetTable
      v-else
      :categories="assetCategoryArray"
      :loading="isDataLoading"
      :pagination="assetPagination"
      :rows="assetArray"
      :selected-rows="selectedAssets"
      @assign="assignOne"
      @assign:multiple="assignMultiple"
      @change-page="getPaginatedAssets"
      @delete="crudMixin_delete(onAssetDelete, 'asset', $event)"
      @edit="crudMixin_openForm('asset', $event)"
      @new-item="crudMixin_openForm('asset', newAssetTemplate)"
      @item-selected="toggleSelectedAsset"
      @return="returnMultiple([$event])"
      @return:multiple="returnMultiple(selectedAssets)"
      @toggle-select-all="toggleAllSelectedAssets"
      @update:filter="getPaginatedAssets(assetPagination.page, $event)"
    />

    <v-dialog
      v-model="isAssetFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="1000"
      persistent
      scrollable
    >
      <AssetForm
        :asset-categories="assetCategoryArray"
        :asset-subcategories="assetSubcategoryArray"
        :asset-types="assetTypeArray"
        :dialog="isAssetFormOpen"
        :form-item="assetFormItem"
        @create="crudMixin_created('asset', $event)"
        @update="crudMixin_updated('asset', $event)"
        @cancel="isAssetFormOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isAssetAssignmentFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <AssetAssignmentForm
        :dialog="isAssetAssignmentFormOpen"
        :assets="assetsToAssign"
        @cancel="isAssetAssignmentFormOpen = false"
        @create="updateAssets"
        @remove:asset="removeAssetToAssign"
      />
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import AssetForm from '../components/forms/AssetForm';
import AssetTable from '../components/tables/AssetTable';
import crudMixin from '../mixins/crud-mixin';
import assetService from '../api/asset-service';
import assetCategoryService from '../api/asset-category-service';
import AssetAssignmentForm from '../components/forms/AssetAssignmentForm';
import eventBus, { onConfirm, OPEN_SNACKBAR } from '../util/event-bus';
import assetAssignmentService from '../api/asset-assignment-service';
import AssetCategoryTable from '../components/tables/AssetCategoryTable';
import AssetCategoryForm from '../components/forms/AssetCategoryForm';
import AssetSubcategoryTable from '../components/tables/AssetSubcategoryTable';
import assetSubcategoryService from '../api/asset-subcategory-service';
import assetTypeService from '../api/asset-type-service';
import AssetTypeTable from '../components/tables/AssetTypeTable';
import AssetSubcategoryForm from '../components/forms/AssetSubcategoryForm';
import AssetTypeForm from '../components/forms/AssetTypeForm';

const TABS = ['list', 'categories', 'subcategories', 'types'];
const DEFAULT_TAB_INDEX = 0;

export default {
  name: 'Assets',

  components: {
    AssetTypeForm,
    AssetSubcategoryForm,
    AssetTypeTable,
    AssetSubcategoryTable,
    AssetCategoryForm,
    AssetCategoryTable,
    AssetAssignmentForm,
    AssetForm,
    AssetTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      activeTab: null,
      areAssetCategoriesLoading: true,
      areAssetSubcategoriesLoading: true,
      areAssetTypesLoading: true,
      assetArray: [],
      assetCategoryArray: [],
      assetCategoryFormItem: {},
      assetFormItem: {},
      assetFilterParams: {},
      assetPagination: {
        page: 1,
      },
      assetSubcategoryArray: [],
      assetSubcategoryFormItem: {},
      assetTypeArray: [],
      assetTypeFormItem: {},
      assetsToAssign: [],
      isAssetAssignmentFormOpen: false,
      isAssetCategoryFormOpen: false,
      isAssetFormOpen: false,
      isAssetSubcategoryFormOpen: false,
      isAssetTypeFormOpen: false,
      isDataLoading: true,
      newAssetTemplate: {
        is_long_term: true,
        purchased_at: format(new Date(), 'yyyy-MM-dd'),
        status: 'in_warehouse',
      },
      onAssetCategoryDelete: assetCategoryService.delete,
      onAssetDelete: assetService.delete,
      onAssetSubcategoryDelete: assetSubcategoryService.delete,
      onAssetTypeDelete: assetTypeService.delete,
      selectedAssets: [],
    };
  },

  created() {
    this.setActiveTab(this.$route.params.tab);
    this.getPaginatedAssets(1);
    assetCategoryService.getAll().then((res) => {
      this.assetCategoryArray = res?.data;
    }).finally(() => {
      this.areAssetCategoriesLoading = false;
    });
    assetSubcategoryService.getAll().then((res) => {
      this.assetSubcategoryArray = res?.data;
    }).finally(() => {
      this.areAssetSubcategoriesLoading = false;
    });
    assetTypeService.getAll().then((res) => {
      this.assetTypeArray = res?.data;
    }).finally(() => {
      this.areAssetTypesLoading = false;
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.setActiveTab(to.params.tab);
    next();
  },

  methods: {
    assignOne(asset) {
      this.assetsToAssign = [asset];
      this.isAssetAssignmentFormOpen = true;
    },

    assignMultiple() {
      this.assetsToAssign = [...this.selectedAssets];
      this.isAssetAssignmentFormOpen = true;
    },

    getPaginatedAssets(pageNum, params) {
      if (params) {
        this.assetFilterParams = params;
      }
      this.crudMixin_getPage(
        assetService.getPage,
        assetService.model,
        pageNum,
        this.assetFilterParams,
      );
    },

    async returnMultiple(assets) {
      const assetsToReturn = assets.filter(a => a.last_assignment);
      try {
        await onConfirm({
          title: this.$t('confirm_asset_return'),
          body: assetsToReturn.map(a => a.description).join(', '),
        });
      } catch (e) {
        return;
      }

      try {
        await assetAssignmentService.returnMultiple({
          inventory_item_ids: assetsToReturn.map(a => a.id),
        });
        for (let i = 0; i < assetsToReturn.length; i++) {
          for (let j = 0; j < this.assetArray.length; j++) {
            if (assetsToReturn[i].id === this.assetArray[j].id) {
              this.$set(this.assetArray[j], 'last_assignment', null);
              break;
            }
          }
        }
        eventBus.$emit(OPEN_SNACKBAR, this.$t('assets_were_returned'));
      } catch (e) {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('failed_to_return_items'));
      }
    },

    onTabChange(newIndex) {
      this.$router.push(`/assets/${TABS[newIndex]}`);
    },

    toggleAllSelectedAssets(wasSelected) {
      if (wasSelected) {
        this.selectedAssets = [...new Set([...this.selectedAssets, ...this.assetArray])];
      } else {
        const toRemove = new Set(this.assetArray);
        this.selectedAssets = [
          ...new Set([...this.selectedAssets].filter(a => !toRemove.has(a))),
        ];
      }
    },

    toggleSelectedAsset(asset, wasSelected) {
      if (wasSelected) {
        this.selectedAssets.push(asset);
      } else {
        this.selectedAssets = this.selectedAssets.filter(a => a.id !== asset.id);
      }
    },

    removeAssetToAssign(asset) {
      this.assetsToAssign = this.assetsToAssign.filter(a => a.id !== asset.id);
    },

    setActiveTab(tabName) {
      const activeTabIndex = TABS.indexOf(tabName);
      this.activeTab = activeTabIndex > -1 ? activeTabIndex : DEFAULT_TAB_INDEX;
    },

    updateAssets(assignedAssets) {
      for (let i = 0; i < assignedAssets.length; i++) {
        for (let j = 0; j < this.assetArray.length; j++) {
          if (assignedAssets[i].id === this.assetArray[j].id) {
            this.assetArray.splice(j, 1, assignedAssets[i]);
            break;
          }
        }
      }
    },
  },
};
</script>
