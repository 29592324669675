<template>
  <div style="width: 100%">
    <div>
      <v-label>
        <span :class="{'error--text': errors.length}">
          {{ formMixin_getRequiredFieldLabel($t('user_signature')) }}
        </span>
      </v-label>
      <v-btn
        icon
        class="mx-2"
        @click="undoCanvas"
      >
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="resetCanvas"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div
      style="width: 100%"
      ref="signatureCanvasWrapper"
    >
      <canvas
        ref="signatureCanvas"
        class="signature-canvas"
        width="500"
        height="300"
      />
    </div>
    <div
      class="v-messages theme--light error--text"
      role="alert"
    >
      <div v-if="errors.length" class="v-messages__wrapper">
        <div class="v-messages__message">
          {{ errors[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import formMixin from '../../mixins/form-mixin';

export default {
  name: 'BaseSignaturePad',

  mixins: [formMixin],

  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    this.$emit('input', new SignaturePad(this.$refs.signatureCanvas));
    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();
    this.$emit('resize:canvas', this.resizeCanvas);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCanvas);
  },

  methods: {
    resizeCanvas() {
      if (!this.$refs?.signatureCanvasWrapper) {
        return;
      }
      const styles = getComputedStyle(this.$refs.signatureCanvasWrapper);
      const padding = +styles.paddingLeft.slice(0, -2) + +styles.paddingRight.slice(0, -2);
      const margin = +styles.marginLeft.slice(0, -2) + +styles.marginRight.slice(0, -2);
      const width = +styles.width.slice(0, -2);
      const widthWithoutSpacing = width - margin - padding;
      this.$refs.signatureCanvas.width = widthWithoutSpacing > 500 ? 500 : widthWithoutSpacing;
    },

    undoCanvas() {
      const data = this.value.toData();
      if (data) {
        data.pop(); // remove the last dot or line
        this.value.fromData(data);
      }
    },

    resetCanvas() {
      this.value.clear();
    },
  },
};
</script>

<style scoped>
  .signature-canvas {
    border: 1px solid #e0e0e0;
    user-select: none;
  }
</style>
