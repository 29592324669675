import http from './http';

const endpoint = 'api/inventory/categories';

const assetCategoryService = {
  model: 'assetCategory',

  getAll: params => http.get(endpoint, { params }),

  create: assetCategory => http.post(`${endpoint}`, assetCategory),

  update: assetCategory => http.put(`${endpoint}/${assetCategory.id}`, assetCategory),

  delete: assetCategory => http.delete(`${endpoint}/${assetCategory.id}`),
};

export default assetCategoryService;
