import http from './http';

const endpoint = 'api/inventory';

const assetAssignmentService = {
  model: 'assetAssignment',

  assignMultiple: payload => http.post(`${endpoint}/issue-multiple`, payload),

  returnMultiple: (payload) => {
    const body = {
      _method: 'DELETE',
      ...payload,
    };
    return http.post(`${endpoint}/return-multiple`, body);
  },
};

export default assetAssignmentService;
