import http from './http';

const endpoint = 'api/inventory/subcategories';

const assetSubcategoryService = {
  model: 'assetSubcategory',

  getAll: params => http.get(endpoint, { params }),

  create: assetSubcategory => http.post(`${endpoint}`, assetSubcategory),

  update: assetSubcategory => http.put(`${endpoint}/${assetSubcategory.id}`, assetSubcategory),

  delete: assetSubcategory => http.delete(`${endpoint}/${assetSubcategory.id}`),
};

export default assetSubcategoryService;
