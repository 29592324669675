<template>
  <v-expansion-panels flat hover>
    <v-expansion-panel v-for="term of terms" :key="term.id" class="outlined">
      <v-expansion-panel-header>
        {{ term.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ol>
          <li v-for="part in term.parts" :key="part.id">
            {{ part.text }}
            <ol v-if="part.subParts">
              <li v-for="subPart in part.subParts" :key="subPart.id">
                {{ subPart.text }}
              </li>
            </ol>
          </li>
        </ol>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AssetAssignmentTerms',

  computed: {
    itemAssignmentDisclaimerParts() {
      const parts = [];
      for (let i = 0; i < 10; i++) {
        parts.push({ id: i + 1, text: this.$t(`item_assignment_disclaimer_parts.${i + 1}`) });
      }
      return parts;
    },

    userDataProcessingPolicyParts() {
      return [
        {
          id: 1,
          text: `${this.$t('user_data_processing_policy_parts.1')}:`,
          subParts: [
            { id: 1, text: this.$t('user_data_processing_policy_parts.1_sub_parts.1') },
            { id: 2, text: this.$t('user_data_processing_policy_parts.1_sub_parts.2') },
            { id: 3, text: this.$t('user_data_processing_policy_parts.1_sub_parts.3') },
            { id: 4, text: this.$t('user_data_processing_policy_parts.1_sub_parts.4') },
            { id: 5, text: this.$t('user_data_processing_policy_parts.1_sub_parts.5') },
            { id: 6, text: this.$t('user_data_processing_policy_parts.1_sub_parts.6') },
            { id: 7, text: this.$t('user_data_processing_policy_parts.1_sub_parts.7') },
          ],
        },
        {
          id: 2,
          text: this.$t('user_data_processing_policy_parts.2'),
        },
        {
          id: 3,
          text: this.$t('user_data_processing_policy_parts.3'),
        },
        {
          id: 4,
          text: this.$t('user_data_processing_policy_parts.4'),
        },
        {
          id: 5,
          text: this.$t('user_data_processing_policy_parts.5'),
        },
      ];
    },

    terms() {
      return [
        {
          id: 1,
          title: this.$t('item_assignment_disclaimer'),
          parts: this.itemAssignmentDisclaimerParts,
        },
        {
          id: 2,
          title: this.$t('user_data_processing_policy'),
          parts: this.userDataProcessingPolicyParts,
        },
      ];
    },
  },
};
</script>
