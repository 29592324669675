import http from '../api/http';

// convert base64/URLEncoded data component to raw binary data held in a string
function dataURItoBlob(dataURI) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

function downloadFile(blob, name) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function downloadFileFromAPI(url, name) {
  http({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    downloadFile(response.data, name);
  });
}

export { dataURItoBlob, downloadFile, downloadFileFromAPI };
