<template>
  <v-chip
    :color="valueColors[value]"
    class="persist-opacity font-weight-medium"
    label
    small
  >
   <slot>
    {{ value }}
   </slot>
  </v-chip>
</template>

<script>
export default {
  name: 'BaseColoredLabel',

  props: {
    // e.g. { active: 'green', suspended: 'grey' }
    valueColors: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .persist-opacity.v-chip:hover::before {
    opacity: 0 !important;
  }
</style>
