<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ $t('asset_assignment') }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-chip
              v-for="asset in assets"
              :key="asset.id"
              close
              @click:close="$emit('remove:asset', asset)"
            >
              {{ asset.description }}
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="assetAssignment.assignedUser"
              :error-messages="errors['assigned_user_id']"
              :label="formMixin_getRequiredFieldLabel($t('user'))"
              :search-function="userSearchFunction"
              item-text="full_name"
              @input="formMixin_setAutocompleteValue($event, assetAssignment, 'assigned_user')"
              @blur="formMixin_clearErrors('assigned_user_id')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="assetAssignment.period_started_at"
              :error-messages="errors['period_started_at']"
              :label="formMixin_getRequiredFieldLabel($t('issued_at'))"
              @blur="formMixin_clearErrors('period_started_at')"
            />
          </v-col>
          <v-col cols="12">
            <AssetAssignmentTerms/>
          </v-col>
          <v-col cols="12">
            <BaseSignaturePad
              v-model="signaturePad"
              :errors="errors['signature_file']"
              @resize:canvas="resizeCanvasFunction = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { format } from 'date-fns';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import BaseAutocomplete from '../base/BaseAutocomplete';
import userService from '../../api/user-service';
import BaseSignaturePad from '../base/BaseSignaturePad';
import assetAssignmentService from '../../api/asset-assignment-service';
import { dataURItoBlob } from '../../util/files';
import AssetAssignmentTerms from '../AssetAssignmentTerms';

export default {
  name: 'AssetAssignmentForm',

  components: {
    AssetAssignmentTerms,
    BaseSignaturePad,
    BaseAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    assets: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      assetAssignment: {},
      userSearchFunction: userService.search,
      signaturePad: {},
      resizeCanvasFunction: null,
    };
  },

  methods: {
    onDialogOpen() {
      this.assetAssignment = {
        period_started_at: format(new Date(), 'yyyy-MM-dd'),
      };
      if (this.signaturePad.canvas) {
        this.signaturePad.clear();
        this.resizeCanvasFunction();
      }
      this.errors = {};
    },

    onSubmit() {
      if (this.signaturePad.isEmpty()) {
        this.$set(this.errors, 'signature_file', [this.$t('signature_is_required')]);
        return;
      }

      const payload = new FormData();
      payload.append('signature_file', dataURItoBlob(this.signaturePad.toDataURL()));
      if (this.assetAssignment.assigned_user_id) {
        payload.append('assigned_user_id', this.assetAssignment.assigned_user_id);
      }
      payload.append('period_started_at', this.assetAssignment.period_started_at);
      this.assets.forEach(a => payload.append('inventory_item_ids[]', a.id));
      this.crudMixin_create(
        assetAssignmentService.assignMultiple,
        assetAssignmentService.model,
        payload,
        this.$t('assets_were_assigned'),
      );
    },
  },
};
</script>
