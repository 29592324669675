<template>
  <div>
    <v-icon
      :disabled="isRequestPending"
      @click="selectImageFile"
    >
      mdi-camera
    </v-icon>
    <input
      accept="image/*"
      ref="hiddenFileInput"
      style="display: none"
      type="file"
      @change="getTextFromImage"
    >
    <v-bottom-sheet
      v-model="isBottomSheetOpen"
      max-width="800"
      inset
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-row dense>
            <v-col cols="12" sm="6">
              {{ titleText }}
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="preserveWhiteSpace"
                class="mt-0 float-left float-sm-right"
                :label="$t('preserve_whitespace')"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text
          class="black--text text-subtitle-1"
          :style="{'white-space': preserveWhiteSpace ? 'pre-line' : 'initial'}"
        >
          {{ imageText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            color="primary"
            text
            @click="isBottomSheetOpen = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="save('serial_no')"
          >
            {{ $t('save_serial_no') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="save('description')"
          >
            {{ $t('save_description') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="$vuetify.breakpoint.xsOnly">
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="isBottomSheetOpen = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import googleService from '../api/google-service';
import i18n from '../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default {
  name: 'TextFromImageSelectForm',

  props: {
    titleText: {
      type: String,
      default: i18n.t('mark_serial_no'),
    },
  },

  data() {
    return {
      imageText: '',
      isRequestPending: false,
      isBottomSheetOpen: false,
      preserveWhiteSpace: true,
      wasSerialNoSelected: false,
      wasDescriptionSelected: false,
    };
  },

  methods: {
    async getTextFromImage(evt) {
      if (!evt.target.files || !evt.target.files[0]) {
        return;
      }

      let base64string = '';
      this.isRequestPending = true;
      this.$emit('start:processing');
      try {
        base64string = await toBase64(evt.target.files[0]);
        // eslint-disable-next-line no-param-reassign
        evt.target.value = '';
        const index = base64string.indexOf('base64,');
        if (index > -1) {
          base64string = base64string.slice(index + 7, base64string.length);
        }
      } catch (e) {
        this.handleError(e);
        return;
      }

      try {
        const res = await googleService.getTextFromImage(base64string);
        if (res?.status !== 200) {
          return;
        }
        const text = res?.data?.responses[0]?.fullTextAnnotation?.text;
        if (text) {
          this.imageText = text;
          this.isBottomSheetOpen = true;
        }
      } catch (e) {
        this.handleError(e);
      }
      this.isRequestPending = false;
      this.$emit('end:processing');
    },

    handleError(e) {
      eventBus.$emit(OPEN_SNACKBAR, this.$t('failed_to_process_image'));
      this.isRequestPending = false;
      this.isBottomSheetOpen = false;
      this.$emit('end:processing');
      console.error(e);
    },

    save(field) {
      if (field === 'serial_no') {
        this.wasSerialNoSelected = true;
      } else {
        this.wasDescriptionSelected = true;
      }
      this.$emit(`select:${field}`, window.getSelection().toString());
      if (this.wasSerialNoSelected && this.wasDescriptionSelected) {
        this.wasSerialNoSelected = false;
        this.wasDescriptionSelected = false;
        this.isBottomSheetOpen = false;
      }
    },

    selectImageFile() {
      this.imageText = '';
      this.$refs.hiddenFileInput.click();
    },
  },
};
</script>
