<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="assetSubcategory.name"
              :error-messages="errors['name']"
              :label="$t('title')"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!assetSubcategory.id" class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import assetSubcategoryService from '../../api/asset-subcategory-service';

export default {
  name: 'AssetSubcategoryForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      assetSubcategory: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.assetSubcategory.id ? 'edit_asset_subcategory' : 'new_asset_subcategory');
    },
  },

  methods: {
    onDialogOpen() {
      this.assetSubcategory = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(assetSubcategoryService, this.assetSubcategory);
    },
  },
};
</script>
