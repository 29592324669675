import http from './http';

const endpoint = 'api/inventory/types';

const assetTypeService = {
  model: 'assetType',

  getAll: params => http.get(endpoint, { params }),

  create: assetType => http.post(`${endpoint}`, assetType),

  update: assetType => http.put(`${endpoint}/${assetType.id}`, assetType),

  delete: assetType => http.delete(`${endpoint}/${assetType.id}`),
};

export default assetTypeService;
