<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :value="selectedRows"
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      disable-sort
      :show-select="$can(['inventory.items.edit'])"
      @click:row="tableMixin_onRowClick"
      @item-selected="$emit('item-selected', $event.item, $event.value)"
      @toggle-select-all="$emit('toggle-select-all', $event.value)"
      @update:page="tableMixin_changePage"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('assets')"
          :create-button="$can(['inventory.items.edit']) ? $t('create_asset') : ''"
          :selected-rows="selectedRows"
          @new-item="$emit('new-item')"
        >
          <template v-slot:append>
            <AssetFilter
              :categories="categories"
              :subcategories="subcategories"
              :types="types"
              @change="$emit('update:filter', $event)"
            />
          </template>
          <template v-slot:mass-actions>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="$emit('assign:multiple')"
                >
                  <v-icon>mdi-clipboard-plus</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('assign_marked') }}
              </span>
            </v-tooltip>
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="$emit('return:multiple')"
                >
                  <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('return_marked') }}
              </span>
            </v-tooltip>
          </template>
        </BaseTableHeader>
      </template>

      <template v-slot:item.status="{ item }">
        <v-tooltip
          :disabled="item.status !== 'discarded' || (!item.discarded_at && !item.discard_reason)"
          transition="none"
          bottom
        >
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
            >
              <BaseColoredLabel
                :value="item.status"
                :value-colors="statusValueColors"
              >
                {{ $t(`asset_statuses.${item.status}`) }}
              </BaseColoredLabel>
            </div>
          </template>
          <span>
            {{ item.discarded_at }}<br>
            {{ item.discard_reason }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.purchased_at="{ item }">
        <span class="text-no-wrap">
          {{ item.purchased_at }}
        </span>
      </template>

      <template v-slot:item.expires_at="{ item }">
        <span :class="[{'is-expired': item.isExpired}, 'text-no-wrap']">
          {{ item.expires_at }}
        </span>
      </template>

      <template v-slot:item.categoryName="{ item }">
        {{ item.categoryName }}
        <div v-if="item.subcategoryrel" class="grey--text">
          {{ item.subcategoryrel.name }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        {{ item.description }}
        <div v-if="item.type" class="grey--text">
          {{ item.type.name }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          v-if="$can(['inventory.items.edit'])"
          :actions="getRowActions(item)"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.status>
            <BaseColoredLabel
              :value="item.status"
              :value-colors="statusValueColors"
            >
              {{ $t(`asset_statuses.${item.status}`) }}
            </BaseColoredLabel>
            <div v-if="item.status === 'discarded' && item.discard_reason">
              {{ item.discarded_at }} {{ item.discard_reason }}
            </div>
          </template>
          <template v-slot:item.categoryName>
            {{ item.categoryName }}
            <div v-if="item.subcategoryrel" class="grey--text">
              {{ item.subcategoryrel.name }}
            </div>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import BaseColoredLabel from '../base/BaseColoredLabel';
import { filters } from '../../util/filters';
import AssetFilter from '../AssetFilter';

export default {
  name: 'AssetTable',

  components: {
    AssetFilter,
    BaseColoredLabel,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    categories: { // for filter
      type: Array,
      default: () => [],
    },
    subcategories: { // for filter
      type: Array,
      default: () => [],
    },
    types: { // for filter
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    selectedRows: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t('category'), value: 'categoryName', hidden: 'smAndDown' },
        { text: this.$t('status'), value: 'status', hidden: 'xsOnly' },
        { text: this.$t('used_by'), value: 'usedBy', hidden: 'xsOnly' },
        { text: this.$t('description'), value: 'description' },
        { text: this.$t('serial_no'), value: 'serial_no', hidden: 'smAndDown' },
        { text: this.$t('purchased_at'), value: 'purchased_at', hidden: 'mdAndDown' },
        { text: this.$t('expires_at'), value: 'expires_at', hidden: 'mdAndDown' },
        { text: this.$t('purchase_price'), value: 'purchasePriceFormatted', hidden: 'xsOnly' },
        { value: 'actions' },
      ],
      statusValueColors: {
        ordered: 'green accent-1',
        in_use: 'blue lighten-4',
        discarded: 'red lighten-3',
      },
    };
  },

  computed: {
    decoratedRows() {
      return this.rows.map(r => ({
        ...r,
        categoryName: r.category?.name,
        isExpired: r.expires_at && new Date() > new Date(r.expires_at),
        purchasePriceFormatted: filters.currency(r.purchase_price),
        usedBy: r?.last_assignment?.assigned_user?.person?.full_name,
      }));
    },
  },

  methods: {
    getRowActions(asset) {
      const actions = [];
      if (asset.last_assignment) {
        actions.push({
          callback: p => this.$emit('return', p),
          label: this.$t('return'),
          icon: 'mdi-clipboard-arrow-left-outline',
        });
      }
      actions.push({
        callback: p => this.$emit('assign', p),
        label: this.$t(asset.last_assignment ? 'assign_again' : 'assign'),
        icon: 'mdi-clipboard-plus',
      });
      actions.push({
        callback: p => this.$emit('edit', p),
        label: this.$t('edit_asset'),
        icon: 'mdi-pencil',
      });
      actions.push({
        callback: p => this.$emit('delete', p),
        label: this.$t('delete_asset'),
        icon: 'mdi-delete',
      });
      return actions;
    },
  },
};
</script>

<style scoped>
  .is-expired {
    color: red;
    font-weight: 500;
  }
</style>
