import axios from 'axios';

const googleService = {
  getTextFromImage: (base64string) => {
    const payload = {
      requests: [
        {
          image: {
            content: base64string,
          },
          features: [
            {
              type: 'TEXT_DETECTION',
            },
          ],
        },
      ],
    };
    return axios.post('https://vision.googleapis.com/v1/images:annotate', payload, {
      params: {
        key: process.env.VUE_APP_GOOGLE_CLOUD_VISION_API_KEY,
      },
    });
  },
};

export default googleService;
