<template>
  <div>
    <v-btn
      class="ml-3"
      color="primary"
      text
      @click="isDrawerOpen = !isDrawerOpen"
    >
      <v-icon left>mdi-filter-variant</v-icon>
      {{ $t(isDrawerOpen ? 'hide_filters' : 'show_filters') }}
    </v-btn>
    <v-navigation-drawer
      v-model="isDrawerOpen"
      height="100%"
      app
      clipped
      right
    >
      <v-row class="px-3 pb-3" dense>
        <v-col cols="12">
          <BaseAutocomplete
            v-model="selectedUser"
            :search-function="userSearchFunction"
            :label="$t('user')"
            item-text="full_name"
            clearable
            @input="setUserId"
          />
        </v-col>
        <v-col v-if="categories.length" cols="12">
          <v-autocomplete
            v-model="filters.category_id"
            :items="categories"
            :label="$t('category')"
            item-value="id"
            item-text="name"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="filters.subcategory_id"
            :items="subcategories"
            :label="$t('subcategory')"
            item-value="id"
            item-text="name"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="filters.type_id"
            :items="types"
            :label="$t('type')"
            item-value="id"
            item-text="name"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="filters.description"
            :label="$t('description')"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="filters.serial_no"
            :label="$t('serial_no')"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="filters.status"
            class="mt-0"
          >
            <template v-slot:label>
              <div class="d-flex align-center" style="min-height: 24px">
                {{ $t('status') }}
                <v-icon
                  v-if="filters.status"
                  class="ml-2"
                  @click="filters.status = null"
                >
                  mdi-close
                </v-icon>
              </div>
            </template>
            <v-radio :label="$t('asset_statuses.ordered')" value="ordered"/>
            <v-radio :label="$t('asset_statuses.in_warehouse')" value="in_warehouse"/>
            <v-radio :label="$t('asset_statuses.in_use')" value="in_use"/>
            <v-radio :label="$t('asset_statuses.discarded')" value="discarded"/>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <BaseDatepickerInput
            v-model="filters.purchased_at_from"
            :label="$t('purchased_at_from')"
          />
        </v-col>
        <v-col cols="12">
          <BaseDatepickerInput
            v-model="filters.purchased_at_to"
            :label="$t('purchased_at_to')"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center" style="min-height: 24px">
            <v-label>
              {{ $t('price_in_euros') }}
            </v-label>
            <v-icon
              v-if="priceRange[0] > 0 || priceRange[1] < 5000"
              class="ml-2"
              @click="resetPriceRange"
            >
              mdi-close
            </v-icon>
          </div>
          <v-range-slider
            v-model="priceRange"
            :min="0"
            :max="5000"
            thumb-label
            @change="updatePriceInterval"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            block
            text
            @click="resetFilters"
          >
            <v-icon left>mdi-filter-variant-remove</v-icon>
            {{ $t('clear_filters') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import BaseAutocomplete from './base/BaseAutocomplete';
import userService from '../api/user-service';
import BaseDatepickerInput from './base/BaseDatepickerInput';

export default {
  name: 'AssetFilter',

  components: { BaseDatepickerInput, BaseAutocomplete },

  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    subcategories: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      defaultFilters: {
        assigned_user_id: null,
        category_id: null,
        subcategory_id: null,
        type_id: null,
        subcategory: null,
        description: null,
        serial_no: null,
        status: null,
        purchased_at_from: null,
        purchased_at_to: null,
        purchase_price_from: null,
        purchase_price_to: null,
      },
      filters: {},
      priceRange: [0, 5000],
      selectedUser: null,
      isDrawerOpen: false,
      userSearchFunction: userService.search,
      timerID: null,
    };
  },

  created() {
    this.resetFilters();
    this.$watch('filters', this.updateFilter, { deep: true });
  },

  methods: {
    updateFilter(val) {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.$emit('change', val);
      }, 500);
    },

    resetFilters() {
      this.resetPriceRange();
      this.filters = { ...this.defaultFilters };
    },

    resetPriceRange() {
      this.priceRange = [0, 5000];
      this.updatePriceInterval(this.priceRange);
    },

    setUserId(user) {
      this.filters.assigned_user_id = user ? user.id : null;
    },

    updatePriceInterval(prices) {
      this.filters.purchase_price_from = prices[0] || null;
      this.filters.purchase_price_to = prices[1] < 5000 ? prices[1] : null;
    },
  },
};
</script>

<style scoped>

</style>
