<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="assetCategory.name"
              :error-messages="errors['name']"
              :label="$t('title')"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!assetCategory.id" class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import assetCategoryService from '../../api/asset-category-service';

export default {
  name: 'AssetCategoryForm',

  components: {
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      assetCategory: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.assetCategory.id ? 'edit_asset_category' : 'new_asset_category');
    },
  },

  methods: {
    onDialogOpen() {
      this.assetCategory = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(assetCategoryService, this.assetCategory);
    },
  },
};
</script>
