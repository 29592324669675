<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="asset.category_id"
              :items="assetCategories"
              :error-messages="errors.category_id"
              :label="formMixin_getRequiredFieldLabel($t('category'))"
              item-value="id"
              item-text="name"
              clearable
              @blur="formMixin_clearErrors('category_id')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="asset.subcategory_id"
              :items="assetSubcategories"
              :error-messages="errors.subcategory_id"
              :label="$t('subcategory')"
              item-value="id"
              item-text="name"
              clearable
              @blur="formMixin_clearErrors('subcategory_id')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="asset.type_id"
              :items="assetTypes"
              :error-messages="errors.type_id"
              :label="$t('type')"
              item-value="id"
              item-text="name"
              clearable
              @blur="formMixin_clearErrors('type_id')"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="asset.description"
              :disabled="isDescriptionImageProcessing"
              :error-messages="errors.description"
              :hint="isDescriptionImageProcessing ? $t('image_is_processing') : ''"
              :label="formMixin_getRequiredFieldLabel($t('description'))"
              :loading="isDescriptionImageProcessing"
              auto-grow
              persistent-hint
              rows="1"
              @blur="formMixin_clearErrors('description')"
            >
              <template v-slot:append>
                <TextFromImageSelect
                  @select:serial_no="$set(asset, 'serial_no', $event)"
                  @select:description="$set(asset, 'description', $event)"
                  @start:processing="isDescriptionImageProcessing = true"
                  @end:processing="isDescriptionImageProcessing = false"
                />
              </template>
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="asset.status"
              :error-messages="errors['status']"
              :label="$t('status')"
              class="mt-0"
              @change="formMixin_clearErrors('status')"
            >
              <v-radio :label="$t('asset_statuses.ordered')" value="ordered"/>
              <v-radio :label="$t('asset_statuses.in_warehouse')" value="in_warehouse"/>
              <v-radio :label="$t('asset_statuses.in_use')" value="in_use"/>
              <v-radio :label="$t('asset_statuses.discarded')" value="discarded"/>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="asset.serial_no"
                  :error-messages="errors.serial_no"
                  :label="$t('serial_no')"
                  :loading="isSerialNoImageProcessing"
                  :hint="isSerialNoImageProcessing ? $t('image_is_processing') : ''"
                  persistent-hint
                  @blur="formMixin_clearErrors('serial_no')"
                >
                  <template v-slot:append>
                    <TextFromImageSelect
                      @select:serial_no="$set(asset, 'serial_no', $event)"
                      @select:description="$set(asset, 'description', $event)"
                      @start:processing="isSerialNoImageProcessing = true"
                      @end:processing="isSerialNoImageProcessing = false"
                    />
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <BaseDatepickerInput
                  v-model="asset.purchased_at"
                  :error-messages="errors.purchased_at"
                  :label="$t('purchased_at')"
                  @blur="formMixin_clearErrors('purchased_at')"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="asset.purchase_price"
                  :error-messages="errors.purchase_price"
                  :label="$t('price_in_euros')"
                  type="number"
                  lang="lt"
                  @blur="formMixin_clearErrors('purchase_price')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="asset.is_long_term"
              :error-messages="errors.is_long_term"
              :label="$t('long_term_asset')"
              @input="formMixin_clearErrors('is_long_term')"
            />
          </v-col>
          <v-col v-if="!asset.is_long_term" cols="12" sm="6">
            <BaseDatepickerInput
              v-model="asset.expires_at"
              :error-messages="errors.expires_at"
              :label="$t('expires_at')"
              @blur="formMixin_clearErrors('expires_at')"
            />
          </v-col>
        </v-row>
        <v-row v-if="asset.status === 'discarded'" dense>
          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="asset.discarded_at"
              :error-messages="errors.discarded_at"
              :label="$t('discarded_at')"
              @blur="formMixin_clearErrors('discarded_at')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              v-model="asset.discard_reason"
              :error-messages="errors.discard_reason"
              :label="$t('reason_for_discarding')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('discard_reason')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span v-if="!asset.id" class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import assetService from '../../api/asset-service';
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import userService from '../../api/user-service';
import TextFromImageSelect from '../TextFromImageSelect';

export default {
  name: 'AssetForm',

  components: { TextFromImageSelect, BaseDatepickerInput },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    assetCategories: {
      type: Array,
      default: () => [],
    },
    assetSubcategories: {
      type: Array,
      default: () => [],
    },
    assetTypes: {
      type: Array,
      default: () => [],
    },
    formItem: Object,
  },

  data() {
    return {
      asset: {},
      assignmentOptions: [
        { value: 'is_for_common_use', text: this.$t('asset_assignment_options.for_common_use') },
        { value: 'is_assigned_to_user', text: this.$t('asset_assignment_options.to_user') },
      ],
      errors: {},
      isDescriptionImageProcessing: false,
      isSerialNoImageProcessing: false,
      isRequestPending: false,
      userSearchFunction: userService.search,
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.asset.id ? 'edit_asset' : 'new_asset');
    },
  },

  methods: {
    onDialogOpen() {
      this.asset = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      const payload = { ...this.asset };
      this.crudMixin_createOrUpdate(assetService, payload);
    },
  },
};
</script>
