var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"value":_vm.selectedRows,"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.rowsPerPage],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedRows,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-sort":"","show-select":_vm.$can(['inventory.items.edit'])},on:{"click:row":_vm.tableMixin_onRowClick,"item-selected":function($event){return _vm.$emit('item-selected', $event.item, $event.value)},"toggle-select-all":function($event){return _vm.$emit('toggle-select-all', $event.value)},"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BaseTableHeader',{attrs:{"title":_vm.$t('assets'),"create-button":_vm.$can(['inventory.items.edit']) ? _vm.$t('create_asset') : '',"selected-rows":_vm.selectedRows},on:{"new-item":function($event){return _vm.$emit('new-item')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('AssetFilter',{attrs:{"categories":_vm.categories,"subcategories":_vm.subcategories,"types":_vm.types},on:{"change":function($event){return _vm.$emit('update:filter', $event)}}})]},proxy:true},{key:"mass-actions",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('assign:multiple')}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-plus")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('assign_marked'))+" ")])]),_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('return:multiple')}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-arrow-left-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('return_marked'))+" ")])])]},proxy:true}])})]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":item.status !== 'discarded' || (!item.discarded_at && !item.discard_reason),"transition":"none","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({},on),[_c('BaseColoredLabel',{attrs:{"value":item.status,"value-colors":_vm.statusValueColors}},[_vm._v(" "+_vm._s(_vm.$t(("asset_statuses." + (item.status))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.discarded_at)),_c('br'),_vm._v(" "+_vm._s(item.discard_reason)+" ")])])]}},{key:"item.purchased_at",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.purchased_at)+" ")])]}},{key:"item.expires_at",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:[{'is-expired': item.isExpired}, 'text-no-wrap']},[_vm._v(" "+_vm._s(item.expires_at)+" ")])]}},{key:"item.categoryName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoryName)+" "),(item.subcategoryrel)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.subcategoryrel.name)+" ")]):_vm._e()]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" "),(item.type)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.type.name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.$can(['inventory.items.edit']))?_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.status",fn:function(){return [_c('BaseColoredLabel',{attrs:{"value":item.status,"value-colors":_vm.statusValueColors}},[_vm._v(" "+_vm._s(_vm.$t(("asset_statuses." + (item.status))))+" ")]),(item.status === 'discarded' && item.discard_reason)?_c('div',[_vm._v(" "+_vm._s(item.discarded_at)+" "+_vm._s(item.discard_reason)+" ")]):_vm._e()]},proxy:true},{key:"item.categoryName",fn:function(){return [_vm._v(" "+_vm._s(item.categoryName)+" "),(item.subcategoryrel)?_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.subcategoryrel.name)+" ")]):_vm._e()]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }